<template>
  <v-card>
    <v-row class="ma-0">
      <v-col
        cols="12"
        sm="12"
        class="total-profit-chart-col"
      >
        <v-card-title class="pt-2">
          Market Share Column Chart
        </v-card-title>
        <div v-if="loaded">
          <vue-apex-charts
            height="500"
            :options="marketShareColumnChartOptions"
            :series="dataTable[1]"
          ></vue-apex-charts>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { mdiDotsVertical, mdiTrendingUp, mdiCurrencyUsd, mdiChartBar } from '@mdi/js'
import { chartService, apiService } from '@/api/index'
// eslint-disable-next-line object-curly-newline
import { marketShareColumnChartOptions } from '@/assets/chartOptions/market'
import { useFilterStore } from '@/stores/FilterStore'

export default {
  components: {
    VueApexCharts,
  },
  setup() {
    const filterStore = useFilterStore()

    return {
      filterStore,
      marketShareColumnChartOptions,
      icons: {
        mdiDotsVertical,
        mdiTrendingUp,
        mdiCurrencyUsd,
        mdiChartBar,
      },
    }
  },
  data() {
    return {
      filterStoreUnsubscribe: null,
      dataTable: [],
      chartSeries: [],
      loaded: false,
    }
  },
  destroyed() {
    this.filterStoreUnsubscribe()
  },
  mounted() {
    this.filterStoreUnsubscribe = this.filterStore.$onAction(({ name, after }) => {
      after(() => {
        if (name === 'refreshCharts') {
          this.getMongoChartData()
        }
      })
    })
    this.getMongoChartData()
  },
  methods: {
    async getMongoChartData() {
      this.loaded = false
      const token = await this.$auth.getTokenSilently()

      const body = {
        dateRange: this.filterStore.dateRange,
        accNumbers: this.filterStore.accNumbers,
        groupCode: this.filterStore.groupCode,
        mfr: this.filterStore.mfr,

        chartType: 'stackedColumnChart',
      }

      //   const pharmList = chartService.getChart(token, 'pharmacyListSelect')
      //   const molList = chartService.getChart(token, 'moleculeList')
      const table = apiService.getData(token, 'marketShareColumnChart', body)

      const [dataTable] = await Promise.all([table])

      this.marketShareColumnChartOptions.xaxis.categories = dataTable[0]
      this.dataTable = dataTable
      this.loaded = true
    },
  },
}
</script>

// <style lang="scss">
// @import '~@core/preset/preset/mixins.scss';
// @import '~vuetify/src/styles/styles.sass';
// @media #{map-get($display-breakpoints, 'sm-and-up')} {
//   .total-profit-chart-col {
//     @include ltr() {
//       border-right: thin solid;
//     }
//     @include rtl() {
//       border-left: thin solid;
//     }
//   }
// }
// @include theme--child(total-profit-chart-col) using ($material) {
//   @media #{map-get($display-breakpoints, 'sm-and-up')} {
//     border-color: map-get($material, 'dividers');
//   }
// }
//
//
</style>
