<template>
  <div v-if="$auth.user[`https://api.pharmaguide.ca/claims/app_metadata`].role === 'superadmin'">
    <router-view></router-view>
    <div v-if="$route.path === '/home'">
      <v-col
        cols="12"
        md="12"
      >
        <pharmacy-list></pharmacy-list>
        <!-- <market-share-column-chart></market-share-column-chart> -->
      </v-col>
    </div>
  </div>
</template>

<script>
import marketShareColumnChart from '@/components/charts/marketShareColumnChart.vue'
import pharmacyList from '@/components/tables/pharmacyList.vue'
import { useFilterStore } from '@/stores/FilterStore'

export default {
  name: 'Home',
  components: {
    marketShareColumnChart,
    pharmacyList,
  },
  setup() {
    const filterStore = useFilterStore()

    return { filterStore }
  },
  data() {
    return {
      user: this.$auth.user,
    }
  },
  mounted() {},
  methods: {},
}
</script>
